import React, {Component} from "react";
import {FaStar, FaStarHalf} from 'react-icons/fa';
import {getClassNameByRating} from '../../helpers/helpers'

class StarsByRating extends Component {
    render() {
        const {rating, isReviewedByCurrentUser, exactRating} = this.props;

        const renderRating = () => {
            if (rating === 0.5) {
                return <FaStarHalf/>
            } else if (rating === 1) {
                return <FaStar/>
            } else if (rating === 1.5) {
                return (
                    <React.Fragment>
                        <FaStar/>
                        <FaStarHalf/>
                    </React.Fragment>
                )
            } else if (rating === 2) {
                return (
                    <React.Fragment>
                        <FaStar/>
                        <FaStar/>
                    </React.Fragment>
                )
            } else if (rating === 2.5) {
                return (
                    <React.Fragment>
                        <FaStar/>
                        <FaStar/>
                        <FaStarHalf/>
                    </React.Fragment>
                )
            } else if (rating === 3) {
                return (
                    <React.Fragment>
                        <FaStar/>
                        <FaStar/>
                        <FaStar/>
                    </React.Fragment>
                )
            } else if (rating === 3.5) {
                return (
                    <React.Fragment>
                        <FaStar/>
                        <FaStar/>
                        <FaStar/>
                        <FaStarHalf/>
                    </React.Fragment>
                )
            } else if (rating === 4) {
                return (
                    <React.Fragment>
                        <FaStar/>
                        <FaStar/>
                        <FaStar/>
                        <FaStar/>
                    </React.Fragment>
                )
            } else if (rating === 4.5) {
                return (
                    <React.Fragment>
                        <FaStar/>
                        <FaStar/>
                        <FaStar/>
                        <FaStar/>
                        <FaStarHalf/>
                    </React.Fragment>
                )
            } else if (rating === 5) {
                return (
                    <React.Fragment>
                        <FaStar/>
                        <FaStar/>
                        <FaStar/>
                        <FaStar/>
                        <FaStar/>
                    </React.Fragment>
                )
            }
        };

        return (
            <div className={`rating-container ${getClassNameByRating(rating)} ${isReviewedByCurrentUser ? 'reviewed-by-me' : ''}`}>
                {renderRating()}
                {exactRating &&
                    <div className="exact-rating">
                        ({exactRating})
                    </div>
                }
            </div>
        )
    }
}

export default StarsByRating