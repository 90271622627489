import React from "react";
import {IoMdRadioButtonOff, IoMdRadioButtonOn} from "react-icons/all";

const RadioButtonRow = props => {
    const {value, group, isSelected, onChangeFn} = props;

    return (
        <div className="filter-row">
            <label>
                <input type="radio" name={group}
                       onChange={onChangeFn}
                       checked={isSelected}/>
                {isSelected ?
                    <IoMdRadioButtonOn/>
                    :
                    <IoMdRadioButtonOff/>
                }

                {value}
            </label>
        </div>
    )
};

export default RadioButtonRow