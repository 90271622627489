import React from 'react';
import {useDispatch} from "react-redux";
import {setWinesSortCriteria} from "../../state/actions";
import ListWithFilters from "../../components/list-with-filters/list-with-filters";
import SortCriteria from "../../components/sort-criteria/sort-criteria";
import {useFilters, useTargetFilters} from "../../helpers/hooks";
import {Helmet} from "react-helmet/es/Helmet";

const HomePage = () => {
    const dispatch = useDispatch();
    const {sortCriteria, selectedWinesCategory} = useFilters();
    const targetFilters = useTargetFilters();
    const selectedCategoryText = selectedWinesCategory === 'all' ? 'kaikki viinit' : selectedWinesCategory;

    const renderSortContent = () => (
        <React.Fragment>
            <SortCriteria
                title="Arvio"
                sortCriteria="review"
                activeSortCriteria={sortCriteria}
                setSortCriteria={(criteria) => dispatch(setWinesSortCriteria(criteria, targetFilters))}
            />

            <SortCriteria
                title="Hinta"
                sortCriteria="price"
                activeSortCriteria={sortCriteria}
                setSortCriteria={(criteria) => dispatch(setWinesSortCriteria(criteria, targetFilters))}
            />

            <SortCriteria
                title="Nimi"
                sortCriteria="name"
                activeSortCriteria={sortCriteria}
                setSortCriteria={(criteria) => dispatch(setWinesSortCriteria(criteria, targetFilters))}
            />
        </React.Fragment>
    );

    return (
        <>
            <Helmet
                title="Arvioi ja merkitse viinejä suosikiksi - Kanpai"
                description="Alkon viinikatalogi käyttäjien arvioilla."
            />

            <ListWithFilters
                title={`Viinit - ${selectedCategoryText}`}
                sortContent={renderSortContent()}
            />
        </>
    )
};

export default HomePage