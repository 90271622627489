import React, { useEffect } from "react";
import "./styles/app.scss";
import { BrowserRouter as Router } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Routes from "./routes";
import Header from "./components/header/header";
import { closeMenus, setWines } from "./state/actions";
import NavigationLinks from "./components/navigation-links/navigation-links";
import { fetchLatestBuildVersion, fetchWines } from "./helpers/fetches";
import AssistiveLinks from "./components/navigation-links/assistive-links";

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    fetchWines().then(wines => {
      dispatch(setWines(wines));
    });

    const getBuildVersion = () => {
      fetchLatestBuildVersion().then(version => {
        if (window.localStorage) {
          window.localStorage.setItem("buildNumber", version);

          setTimeout(() => {
            if (
              Number(window.localStorage.getItem("buildNumber")) !== version
            ) {
              window.location.reload();
            }
          }, 100);
        }
      });
    };

    const handleVisibilityChange = event => {
      if (!event.target.hidden) {
        getBuildVersion();
      }
    };

    getBuildVersion();
    document.addEventListener(
      "visibilitychange",
      handleVisibilityChange,
      false
    );
  }, [dispatch]);

  const { menuOpen, filtersOpen } = useSelector(
    state => state.common,
    shallowEqual
  );

  return (
    <Router>
      <div className="App">
        <Header />
        <div className="page-container">
          <Routes />
        </div>
      </div>

      <div className={`global-menu ${menuOpen ? "open" : "closed"}`}>
        <div>
          <NavigationLinks />
        </div>

        <div>
          <AssistiveLinks />
        </div>
      </div>

      <div
        className={`menu-overlay ${
          menuOpen || filtersOpen ? "open" : "closed"
        }`}
        onClick={() => dispatch(closeMenus())}
      />
    </Router>
  );
};

export default App;
