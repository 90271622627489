import _ from "lodash";

export const bottleSizeSmall = "Alle 0,75 l";
export const bottleSizeNormal = "0,75 l";
export const bottleSizeLarge = "Yli 0,75 l";
export const bottleSizes = [bottleSizeSmall, bottleSizeNormal, bottleSizeLarge];

const getUserFilterFromLocalStorage = (targetFilters, filter, fallback) => {
  try {
    const filters = window.localStorage.getItem("userFilters");
    const parsed = JSON.parse(filters);
    return parsed[targetFilters][filter];
  } catch (e) {
    return fallback;
  }
};

export const winesInitialFilters = {
  selectedWinesCategory: "all",
  selectedSelection: "all",
  selectedBottleSize: "all",
  selectedCountries: [],
  selectedRegions: [],
  selectedGrapes: [],
  selectedDescriptions: [],
  selectedSpecialGroups: [],
  priceMin: "",
  priceMax: "",
  sortCriteria: "reviewAsc",
  searchCriteria: ""
};

export const userReviewsInitialFilters = {
  selectedWinesCategory: "all",
  selectedSelection: "all",
  selectedBottleSize: "all",
  selectedCountries: [],
  selectedRegions: [],
  selectedGrapes: [],
  selectedDescriptions: [],
  selectedSpecialGroups: [],
  priceMin: "",
  priceMax: "",
  sortCriteria: "reviewDateDesc",
  searchCriteria: ""
};

export const userBookmarksInitialFilters = {
  selectedWinesCategory: "all",
  selectedSelection: "all",
  selectedBottleSize: "all",
  selectedCountries: [],
  selectedRegions: [],
  selectedGrapes: [],
  selectedDescriptions: [],
  selectedSpecialGroups: [],
  priceMin: "",
  priceMax: "",
  sortCriteria: "bookmarkDateDesc",
  searchCriteria: ""
};

export const filtersInitialState = {
  winesFilters: winesInitialFilters,
  userReviewsFilters: userReviewsInitialFilters,
  userBookmarksFilters: userBookmarksInitialFilters,
  activeFilters: "wines"
};

const setStateToTargetFilters = (target, filters, state) => {
  if (window.localStorage) {
    window.localStorage.setItem("userFilters", JSON.stringify(state));
  }

  if (target === "wines") {
    return {
      winesFilters: {
        ...state.winesFilters,
        ...filters
      },
      activeFilters: "wines"
    };
  }

  if (target === "reviews") {
    return {
      userReviewsFilters: {
        ...state.userReviewsFilters,
        ...filters
      },
      activeFilters: "reviews"
    };
  }

  if (target === "bookmarks") {
    return {
      userBookmarksFilters: {
        ...state.userBookmarksFilters,
        ...filters
      },
      activeFilters: "bookmarks"
    };
  }
};

const getTargetKey = filter => {
  if (filter === "wines") {
    return "winesFilters";
  }

  if (filter === "reviews") {
    return "userReviewsFilters";
  }

  if (filter === "bookmarks") {
    return "userBookmarksFilters";
  }
};

export default function(state = filtersInitialState, action) {
  switch (action.type) {
    case "SET_SELECTED_WINES_CATEGORY":
      const result = {
        selectedWinesCategory: action.selectedWinesCategory,
        selectedCountries: [],
        selectedRegions: [],
        selectedGrapes: [],
        selectedDescriptions: [],
        selectedSpecialGroups: [],
        selectedBottleSize: "all"
      };

      return {
        ...state,
        ...setStateToTargetFilters(action.targetFilters, result, state)
      };

    case "ADD_FILTER_COUNTRY": {
      return {
        ...state,
        ...setStateToTargetFilters(
          action.targetFilters,
          {
            selectedCountries: [
              ...state[getTargetKey(action.targetFilters)].selectedCountries,
              action.country
            ]
          },
          state
        )
      };
    }

    case "REMOVE_FILTER_COUNTRY": {
      return {
        ...state,
        ...setStateToTargetFilters(
          action.targetFilters,
          {
            selectedCountries: _.without(
              state[getTargetKey(action.targetFilters)].selectedCountries,
              action.country
            )
          },
          state
        )
      };
    }

    case "ADD_FILTER_GRAPE": {
      return {
        ...state,
        ...setStateToTargetFilters(
          action.targetFilters,
          {
            selectedGrapes: [
              ...state[getTargetKey(action.targetFilters)].selectedGrapes,
              action.grape
            ]
          },
          state
        )
      };
    }

    case "REMOVE_FILTER_GRAPE": {
      return {
        ...state,
        ...setStateToTargetFilters(
          action.targetFilters,
          {
            selectedGrapes: _.without(
              state[getTargetKey(action.targetFilters)].selectedGrapes,
              action.grape
            )
          },
          state
        )
      };
    }

    case "ADD_FILTER_REGION": {
      return {
        ...state,
        ...setStateToTargetFilters(
          action.targetFilters,
          {
            selectedRegions: [
              ...state[getTargetKey(action.targetFilters)].selectedRegions,
              action.region
            ]
          },
          state
        )
      };
    }

    case "REMOVE_FILTER_REGION": {
      return {
        ...state,
        ...setStateToTargetFilters(
          action.targetFilters,
          {
            selectedRegions: _.without(
              state[getTargetKey(action.targetFilters)].selectedRegions,
              action.region
            )
          },
          state
        )
      };
    }

    case "ADD_FILTER_DESCRIPTION": {
      return {
        ...state,
        ...setStateToTargetFilters(
          action.targetFilters,
          {
            selectedDescriptions: [
              ...state[getTargetKey(action.targetFilters)].selectedDescriptions,
              action.description
            ]
          },
          state
        )
      };
    }

    case "REMOVE_FILTER_DESCRIPTION": {
      return {
        ...state,
        ...setStateToTargetFilters(
          action.targetFilters,
          {
            selectedDescriptions: _.without(
              state[getTargetKey(action.targetFilters)].selectedDescriptions,
              action.description
            )
          },
          state
        )
      };
    }

    case "ADD_FILTER_SPECIAL_GROUP": {
      return {
        ...state,
        ...setStateToTargetFilters(
          action.targetFilters,
          {
            selectedSpecialGroups: [
              ...state[getTargetKey(action.targetFilters)]
                .selectedSpecialGroups,
              action.specialGroup
            ]
          },
          state
        )
      };
    }

    case "REMOVE_FILTER_SPECIAL_GROUP": {
      return {
        ...state,
        ...setStateToTargetFilters(
          action.targetFilters,
          {
            selectedSpecialGroups: _.without(
              state[getTargetKey(action.targetFilters)].selectedSpecialGroups,
              action.specialGroup
            )
          },
          state
        )
      };
    }

    case "SET_FILTER_BOTTLE_SIZE": {
      return {
        ...state,
        ...setStateToTargetFilters(
          action.targetFilters,
          {
            selectedBottleSize: action.bottleSize
          },
          state
        )
      };
    }

    case "SET_WINES_SELECTION": {
      return {
        ...state,
        ...setStateToTargetFilters(
          action.targetFilters,
          {
            selectedSelection: action.selection
          },
          state
        )
      };
    }

    case "SET_PRICE_MIN": {
      return {
        ...state,
        ...setStateToTargetFilters(
          action.targetFilters,
          {
            priceMin: action.priceMin
          },
          state
        )
      };
    }

    case "SET_PRICE_MAX": {
      return {
        ...state,
        ...setStateToTargetFilters(
          action.targetFilters,
          {
            priceMax: action.priceMax
          },
          state
        )
      };
    }

    case "SET_WINES_SORT_CRITERIA": {
      return {
        ...state,
        ...setStateToTargetFilters(
          action.targetFilters,
          {
            sortCriteria: action.winesSortCriteria
          },
          state
        )
      };
    }

    case "RESET_SELECTION_FILTERS": {
      return {
        ...state,
        ...setStateToTargetFilters(
          action.targetFilters,
          {
            selectedCountries: [],
            selectedRegions: [],
            selectedGrapes: [],
            selectedDescriptions: [],
            selectedSpecialGroups: [],
            priceMin: "",
            priceMax: ""
          },
          state
        )
      };
    }

    case "SEARCH_WINES": {
      return {
        ...state,
        ...setStateToTargetFilters(
          action.targetFilters,
          {
            searchCriteria: action.criteria
          },
          state
        )
      };
    }

    default:
      return state;
  }
}
