import {NavLink} from "react-router-dom";
import React from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {closeMenus} from "../../state/actions";

const NavigationLinks = () => {
    const dispatch = useDispatch();
    const {loggedIn, reviews, bookmarks} = useSelector(state => state.user, shallowEqual);

    return (
        <React.Fragment>
            <NavLink exact={true} activeClassName="active" to={'/'} onClick={() => dispatch(closeMenus())}>Viinit</NavLink>

            {loggedIn ?
                <NavLink activeClassName="active" to={'/user/reviews'} onClick={() => dispatch(closeMenus())}>
                    Arvioni ({reviews.length})
                </NavLink>
                :
                <span>Arvioni</span>
            }

            {loggedIn ?
                <NavLink activeClassName="active" to={'/user/bookmarks'} onClick={() => dispatch(closeMenus())}>
                    Muistilista ({bookmarks.length})
                </NavLink>
                :
                <span>Muistilista</span>
            }
        </React.Fragment>
    )
};

export default NavigationLinks