import Comment from "./comment";
import React from "react";
import {useCommentsForCurrentWine} from "../../helpers/hooks";

const AllCommentsList = () => {
    const comments = useCommentsForCurrentWine();

    if (comments.length === 0) {
        return null
    }

    return (
        <div className="review-block">
            <h3>Kommentit ({comments.length})</h3>
            {comments.map((comment, key) => <Comment comment={comment} key={key} shouldShowDelete={false}/>)}
        </div>
    )
};

export default AllCommentsList