import _ from "lodash";
import {
  bottleSizeLarge,
  bottleSizeNormal,
  bottleSizeSmall,
} from "../state/filtersReducer";

export const CATEGORY_RED_WINES = "punaviinit";
export const CATEGORY_WHITE_WINES = "valkoviinit";
export const CATEGORY_ROSE_WINES = "roseeviinit";
export const CATEGORY_SPARKLING_WINES = "kuohuviinit & samppanjat";

export const getClassNameByRating = (rating) => {
  if (rating <= 3) {
    return "bad";
  } else if (rating <= 4) {
    return "good";
  } else if (rating <= 4.5) {
    return "better";
  } else if (rating === 5) {
    return "best";
  }
};

export const sortWines = (wines, sortCriteria) => {
  const sortwinesWithReviews = (wines, direction) => {
    let first = [];
    let others = [];

    wines.forEach((wine) => {
      if (wine.hasOwnProperty("averageRating")) {
        first.push(wine);
      } else {
        others.push(wine);
      }
    });

    first = _.orderBy(first, ["averageRating"], direction);
    others.sort();

    return first.concat(others);
  };

  switch (sortCriteria) {
    case "nameAsc":
      return _.orderBy(wines, ["name"], "asc");
    case "nameDesc":
      return _.orderBy(wines, ["name"], "desc");
    case "priceAsc":
      return _.orderBy(wines, [(wine) => parseFloat(wine.price)], "asc");
    case "priceDesc":
      return _.orderBy(wines, [(wine) => parseFloat(wine.price)], "desc");
    case "reviewDateAsc":
      return _.orderBy(wines, [(wine) => parseInt(wine.review.date)], "asc");
    case "reviewDateDesc":
      return _.orderBy(wines, [(wine) => parseInt(wine.review.date)], "desc");
    case "bookmarkDateAsc":
      return _.orderBy(wines, [(wine) => parseInt(wine.bookmarkDate)], "asc");
    case "bookmarkDateDesc":
      return _.orderBy(wines, [(wine) => parseInt(wine.bookmarkDate)], "desc");
    case "reviewDesc":
      return sortwinesWithReviews(wines, "asc");
    case "reviewAsc":
      return sortwinesWithReviews(wines, "desc");
    default:
      return _.orderBy(wines, ["name"], "asc");
  }
};

export const getAverageRating = (reviews) =>
  reviews.reduce((a, b) => a + b.rating, 0) / reviews.length;

export const roundHalf = (num) => Math.round(num * 2) / 2;

export const filterWinesByCategory = (wines, selectedCategory) =>
  wines.filter((i) => i.type === selectedCategory);

export const filterWineByCategory = (wine, selectedWinesCategory) => {
  return wine.type === selectedWinesCategory;
};

export const filterWineBySelection = (wine, selectedSelection) => {
  return wine.selection === selectedSelection;
};

export const filterWineByCountry = (wine, selectedCountries) => {
  return selectedCountries.includes(wine.country);
};

export const filterWineByRegion = (wine, selectedRegions) => {
  return selectedRegions.includes(wine.region);
};

export const filterWineByGrape = (wine, selectedGrapes) => {
  return _.intersection(wine.grapes, selectedGrapes).length > 0;
};

export const filterWineByDescription = (wine, selectedDescriptions) => {
  return selectedDescriptions.includes(wine.description.split(",")[0]);
};

export const filterWinesByPriceMin = (wine, priceMin) => {
  return parseFloat(wine.price) >= priceMin;
};

export const filterWinesByPriceMax = (wine, priceMax) => {
  return parseFloat(wine.price) <= priceMax;
};

export const filterWineBySpecialGroup = (wine, selectedSpecialGroups) => {
  return selectedSpecialGroups.includes(wine.specialGroup);
};

export const filterWineByBottleSize = (wine, selectedBottleSize) => {
  const bottleSize = parseFloat(
    wine.bottleSize.replace(" l", "").replace(",", ".")
  );

  if (selectedBottleSize === bottleSizeSmall) {
    return bottleSize < 0.75;
  } else if (selectedBottleSize === bottleSizeNormal) {
    return bottleSize === 0.75;
  } else if (selectedBottleSize === bottleSizeLarge) {
    return bottleSize > 0.75;
  }
};

export const filterWineBySearchCriteria = (wine, criteria) => {
  return _.deburr(wine.name.toLowerCase()).includes(criteria.toLowerCase());
};

export const filterWinesByFilters = (wines, filters) => {
  const {
    selectedWinesCategory,
    selectedSelection,
    selectedCountries,
    selectedRegions,
    selectedGrapes,
    selectedBottleSize,
    selectedDescriptions,
    selectedSpecialGroups,
    sortCriteria,
    priceMin,
    priceMax,
    searchCriteria,
  } = filters;

  const hasFilteredCountries = selectedCountries.length > 0;
  const hasFilteredRegions = selectedRegions.length > 0;
  const hasFilteredGrapes = selectedGrapes.length > 0;
  const hasFilteredDescriptions = selectedDescriptions.length > 0;
  const hasFilteredSpecialGroups = selectedSpecialGroups.length > 0;
  const hasFilteredPriceMin = priceMin !== "";
  const hasFilteredPriceMax = priceMax !== "";

  const hasSelectedAllCategories = selectedWinesCategory === "all";
  const hasSelectedAllSelections = selectedSelection === "all";
  const hasSelectedAllBottleSizes = selectedBottleSize === "all";

  const hasFilteredSearchCriteria = searchCriteria.length > 0;

  const filteredWines = wines
    .filter((wine) =>
      hasSelectedAllCategories
        ? wine
        : filterWineByCategory(wine, selectedWinesCategory)
    )
    .filter((wine) =>
      hasSelectedAllSelections
        ? wine
        : filterWineBySelection(wine, selectedSelection)
    )
    .filter((wine) =>
      hasSelectedAllBottleSizes
        ? wine
        : filterWineByBottleSize(wine, selectedBottleSize)
    )
    .filter((wine) =>
      hasFilteredCountries ? filterWineByCountry(wine, selectedCountries) : wine
    )
    .filter((wine) =>
      hasFilteredRegions ? filterWineByRegion(wine, selectedRegions) : wine
    )
    .filter((wine) =>
      hasFilteredGrapes ? filterWineByGrape(wine, selectedGrapes) : wine
    )
    .filter((wine) =>
      hasFilteredDescriptions
        ? filterWineByDescription(wine, selectedDescriptions)
        : wine
    )
    .filter((wine) =>
      hasFilteredSpecialGroups
        ? filterWineBySpecialGroup(wine, selectedSpecialGroups)
        : wine
    )
    .filter((wine) =>
      hasFilteredPriceMin ? filterWinesByPriceMin(wine, priceMin) : wine
    )
    .filter((wine) =>
      hasFilteredPriceMax ? filterWinesByPriceMax(wine, priceMax) : wine
    )
    .filter((wine) =>
      hasFilteredSearchCriteria
        ? filterWineBySearchCriteria(wine, searchCriteria)
        : wine
    );

  return sortWines(filteredWines, sortCriteria);
};

export const getRegionsForCountry = (wines, country) => {
  const countryFilteredWines = wines.filter((wine) => wine.country === country);
  const sortedRegionsArr = countryFilteredWines
    .map((wine) => wine.region)
    .sort();
  const uniqueRegions = _.compact(
    _.chain(countryFilteredWines).map("region").uniq().value()
  );

  const regionsByAppearance = uniqueRegions.map((region) => {
    return {
      region: region,
      amount: sortedRegionsArr.filter((item) => item === region).length,
    };
  });

  return _.sortBy(regionsByAppearance, "amount").reverse();
};

export const getCountries = (wines) => {
  const sortedCountriesArr = wines.map((wine) => wine.country).sort();
  const uniqueCountries = _.chain(wines).map("country").uniq().value();
  const countriesByAppearance = uniqueCountries.map((country) => {
    return {
      country: country,
      amount: sortedCountriesArr.filter((item) => item === country).length,
      regions: getRegionsForCountry(wines, country),
    };
  });

  return _.sortBy(countriesByAppearance, "amount").reverse();
};

export const getGrapes = (wines) => {
  const sortedGrapesArr = _.flatten(
    wines.map((wine) => wine.grapes.map((grape) => grape))
  ).sort();
  const uniqueGrapes = _.uniq(sortedGrapesArr);

  const grapesByAppearance = uniqueGrapes.map((grape) => {
    return {
      grape: grape,
      amount: sortedGrapesArr.filter((item) => item === grape).length,
    };
  });

  return _.sortBy(grapesByAppearance, "amount").reverse();
};

export const searchGrapesByCriteria = (criteria, grapes) => {
  return grapes.filter((grape) => {
    return grape.grape.toLowerCase().includes(criteria.toLowerCase());
  });
};

export const getDescriptions = (wines) => {
  const sortedDescriptionsArr = _.flatten(
    wines.map((wine) => wine.description.split(",")[0])
  ).sort();
  const uniqueDescriptions = _.uniq(sortedDescriptionsArr);

  const descriptionsByAppearance = uniqueDescriptions.map((description) => {
    return {
      description: description,
      amount: sortedDescriptionsArr.filter((item) => item === description)
        .length,
    };
  });

  return _.sortBy(descriptionsByAppearance, "amount").reverse();
};

export const getSpecialGroups = (wines) => {
  const sortedSpecialGroupsArr = _.compact(
    wines.map((wine) => wine.specialGroup).sort()
  );
  const uniqueSpecialGroups = _.compact(
    _.chain(wines).map("specialGroup").uniq().value()
  );

  const specialGroupsByAppearance = uniqueSpecialGroups.map((specialGroup) => {
    return {
      specialGroup,
      amount: sortedSpecialGroupsArr.filter((item) => item === specialGroup)
        .length,
    };
  });

  return _.sortBy(specialGroupsByAppearance, "amount").reverse();
};

export const limitsForCurrentCategory = (selectedWinesCategory) => {
  const COUNTRIES_RED = 9;
  const COUNTRIES_WHITE = 11;
  const COUNTRIES_ROSE = 15;
  const COUNTRIES_SPARKLING = 9;

  const GRAPES_RED = 11;
  const GRAPES_WHITE = 11;
  const GRAPES_ROSE = 15;
  const GRAPES_SPARKLING = 9;

  switch (selectedWinesCategory) {
    case "all":
      return {
        countries: COUNTRIES_RED,
        grapes: GRAPES_RED,
      };
    case CATEGORY_RED_WINES:
      return {
        countries: COUNTRIES_RED,
        grapes: GRAPES_RED,
      };
    case CATEGORY_WHITE_WINES:
      return {
        countries: COUNTRIES_WHITE,
        grapes: GRAPES_WHITE,
      };
    case CATEGORY_ROSE_WINES:
      return {
        countries: COUNTRIES_ROSE,
        grapes: GRAPES_ROSE,
      };
    case CATEGORY_SPARKLING_WINES:
      return {
        countries: COUNTRIES_SPARKLING,
        grapes: GRAPES_SPARKLING,
      };
    default:
      return {
        countries: COUNTRIES_RED,
        grapes: GRAPES_RED,
      };
  }
};

export const countActiveFilters = (filters) => {
  const {
    selectedCountries,
    selectedGrapes,
    selectedDescriptions,
    selectedSpecialGroups,
    priceMin,
    priceMax,
  } = filters;

  const selectedCountriesFilters = selectedCountries.length === 0 ? 0 : 1;
  const selectedGrapesFilters = selectedGrapes.length === 0 ? 0 : 1;
  const selectedDescriptionsFilters = selectedDescriptions.length === 0 ? 0 : 1;
  const selectedSpecialGroupsFilters =
    selectedSpecialGroups.length === 0 ? 0 : 1;
  const priceFilters = priceMin !== "" || priceMax !== "" ? 1 : 0;

  return (
    selectedCountriesFilters +
    selectedGrapesFilters +
    selectedDescriptionsFilters +
    selectedSpecialGroupsFilters +
    priceFilters
  );
};

export const normalizeNumber = (input) => {
  if (typeof input === "string") {
    return input.replace(".", ",");
  } else {
    return input;
  }
};

function sortInputFirst(input, data) {
  let first = [];
  let others = [];
  for (let i = 0; i < data.length; i++) {
    if (data[i].name.toLowerCase().indexOf(input) === 0) {
      first.push(data[i]);
    } else {
      others.push(data[i]);
    }
  }
  first.sort();
  others.sort();
  return first.concat(others);
}

export const getWinesFromSearchCriteria = (criteria, wines) => {
  console.log("test");
  if (criteria.length > 0) {
    const filteredWines = wines.filter((wine) =>
      _.deburr(wine.name.toLowerCase()).includes(criteria.toLowerCase())
    );
    return sortInputFirst(criteria.toLowerCase(), filteredWines);
  } else {
    return [];
  }
};

export const saveUserFilterToLocalStorage = (targetFilters, key, value) => {
  if (window.localStorage) {
    const result = JSON.stringify({
      [targetFilters]: {
        [key]: value,
      },
    });

    window.localStorage.setItem("userFilters", result);
  }
};
