import React, { useMemo } from "react";
import FilterByType from "./filter-by-type";
import FilterByCountry from "./filter-by-country";
import FilterByGrape from "./filter-by-grape";
import FilterByDescription from "./filter-by-description";
import FilterBySpecialGroup from "./filter-by-special-group";
import FilterByBottleSize from "./filter-by-bottle-size";
import FilterBySelection from "./filter-by-selection";
import FilterByPrice from "./filter-by-price";
import {useFilters, useInitialFilters, useTargetFilters} from "../../helpers/hooks";
import _ from 'lodash';
import {useDispatch} from "react-redux";
import {resetSelectionFilters} from "../../state/actions";
import {countActiveFilters} from "../../helpers/helpers";

const Filters = () => {
    const dispatch = useDispatch();
    const currentFilters = useFilters();
    const total = countActiveFilters(currentFilters);
    const initialFilters = useInitialFilters();
    const targetFilters = useTargetFilters();
    const strip = filters => _.pick(filters, 'selectedCountries', 'selectedRegions', 'selectedGrapes', 'selectedDescriptions', 'selectedSpecialGroups', 'priceMin', 'priceMax')
    const filtersAreSame = _.isEqual(strip(currentFilters), strip(initialFilters));

    return useMemo(() => {
        return (
            <React.Fragment>
                <div className="filters-panel-title">
                    <div className="wrapper">
                        <h3>Suodattimet {total !== 0 && `(${total})`}</h3>
                        {!filtersAreSame &&
                        <button className="small" onClick={() => dispatch(resetSelectionFilters(targetFilters))}>Palauta</button>
                        }
                    </div>
                </div>
    
                <FilterByType/>
                <FilterByPrice/>
                <FilterByCountry/>
                <FilterByGrape/>
                <FilterByDescription/>
                <FilterBySpecialGroup/>
                <FilterBySelection/>
                <FilterByBottleSize/>
            </React.Fragment>
        )
    }, [filtersAreSame, dispatch, targetFilters, total])
};

export default Filters