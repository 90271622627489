import FilterRow from "../filter-row/filter-row";
import React, {useState} from "react";
import {useCountries, useFilters, useTargetFilters} from "../../helpers/hooks";
import {addFilterCountry, removeFilterCountry} from "../../state/actions";
import {useDispatch} from "react-redux";
import {limitsForCurrentCategory} from "../../helpers/helpers";
import FilterGroup from "./filter-group";

const FilterByCountry = () => {
    const dispatch = useDispatch();
    const targetFilters = useTargetFilters();
    const {selectedCountries} = useFilters();
    const [countriesExpanded, setCountriesExpanded] = useState(false);
    const countries = useCountries();
    const countriesToRender = countriesExpanded ? countries : countries.slice(0, limitsForCurrentCategory().countries);
    const currentCountryIsSelected = country => selectedCountries.includes(country);
    const handleCountryFilterClick = country => currentCountryIsSelected(country) ? dispatch(removeFilterCountry(country, targetFilters)) : dispatch(addFilterCountry(country, targetFilters));

    return (
        <FilterGroup title="Maa" amount={countries.length}>
            {countriesToRender.map((country, key) => (
                <FilterRow
                    key={key}
                    title={country.country}
                    amount={country.amount}
                    isSelected={currentCountryIsSelected(country.country)}
                    clickFn={() => handleCountryFilterClick(country.country)}
                    regions={country.regions}
                />
            ))}

            <div className="show-all"
                 onClick={() => setCountriesExpanded(!countriesExpanded)}>
                {countriesExpanded ? 'Piilota' : 'Näytä'} kaikki maat
            </div>
        </FilterGroup>
    )
};

export default FilterByCountry