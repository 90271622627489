import React, {Component} from 'react';

class TermsPage extends Component {
    render() {
        return (
            <div className="static-page">
                <h1>Käyttöehdot</h1>
                <p>Käyttäjästä tallennetaan vain sivuston käytön kannalta olennainen tieto (Googlelta saatava tunniste, ns. id), eikä sivustolle tallennettua dataa luovuteta kolmansille osapuolille.</p>
                <p>Sivuston ylläpitäjällä on oikeus muuttaa tai poistaa mitä tahansa sivustolle jätettyä tietoa.</p>
                <p>Sivuston ylläpitäjään voi olla yhteydessä sähköpostitse: <a href="mailto:aki.narhi@reaktor.com">aki.narhi@reaktor.com</a></p>
            </div>
        )
    }
}

export default TermsPage