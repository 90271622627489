import React, {useEffect, useState, useRef} from 'react';
import {useDispatch, useSelector} from "react-redux"
import {toggleMenu, searchWines, closeMenus} from "../../state/actions";
import {IoIosLock, MdMenu, FaTimes} from "react-icons/all";
import {useLocationHasChanged, useTargetFilters} from "../../helpers/hooks";
import NavigationLinks from "../navigation-links/navigation-links";
import AssistiveLinks from "../navigation-links/assistive-links";
import {NavLink, useLocation} from "react-router-dom";

const Header = () => {
    const dispatch = useDispatch();
    const [lol, setLol] = useState('');
    const inputRef = useRef();
    const locationHasChanged = useLocationHasChanged();
    const {loggedIn} = useSelector(state => state.user);
    const path = useLocation().pathname;
    const targetFilters = useTargetFilters();

    useEffect(() => {
        if (locationHasChanged) {
            dispatch(closeMenus())
        }
    });

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.keyCode === 27 && document.activeElement === inputRef.current) {
                dispatch(searchWines('', targetFilters));
                setLol('')
            }
        };

        document.addEventListener("keydown", handleKeyDown);
    }, [dispatch, targetFilters]);

    const clearInput = () => {
        setLol('');
        dispatch(searchWines('', targetFilters));
    };

    const handleInputChange = () => {
        setLol(inputRef.current.value);
        dispatch(searchWines(inputRef.current.value, targetFilters));
    };

    const shouldDisplayMainNavigation = () => {
        if (path === '/information' || path === '/terms') {
            return false
        } else {
            return true
        }
    };

    return (
        <React.Fragment>
            <div className="top-header">
                <div className="page-container top-header-content">
                    <NavLink to={"/"}>
                        <img src="/kanpai-logo.svg" alt="Kanpai" className="logo"/>
                    </NavLink>

                    <div className="search-wrapper">
                        {path === '/' && 
                            <div className="input-container">
                            <input className="search"
                                value={lol}
                                ref={inputRef}
                                onChange={handleInputChange}
                                placeholder="Etsi viiniä"
                                autoCorrect="off"
                                autoFocus={true}
                            />

                            <FaTimes className={`${lol.length > 0 ? 'visible' : 'hidden'} close-icon`}
                                    onClick={() => clearInput()}
                            />
                        </div>
                        }
                    </div>

                    <div className="links">
                        <AssistiveLinks/>
                    </div>
                </div>
            </div>

            <header className="app-header">
                <div className="header-container">
                    <div className="mobile-menu-button hamburger-container" onClick={() => dispatch(toggleMenu())}>
                        {loggedIn ?
                            <MdMenu/>
                            :
                            <IoIosLock/>
                        }
                    </div>

                    {shouldDisplayMainNavigation() &&
                    <div className="navigation-row">
                        <div className="navigation-links">
                            <NavigationLinks/>
                        </div>
                    </div>
                    }
                </div>
            </header>
        </React.Fragment>
    )
};

export default Header