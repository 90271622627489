import React from 'react';
import {useDispatch} from "react-redux"
import ListWithFilters from "../../components/list-with-filters/list-with-filters";
import {setWinesSortCriteria} from '../../state/actions'
import SortCriteria from "../../components/sort-criteria/sort-criteria";
import {useFilters, useTargetFilters} from "../../helpers/hooks";

const UserReviewsPage = () => {
    const dispatch = useDispatch();
    const {sortCriteria, selectedWinesCategory} = useFilters();
    const targetFilters = useTargetFilters();
    const selectedCategoryText = selectedWinesCategory === 'all' ? 'kaikki viinit' : selectedWinesCategory;

    const renderSortContent = () => (
        <React.Fragment>
            <SortCriteria
                title="Päivämäärä"
                sortCriteria="reviewDate"
                activeSortCriteria={sortCriteria}
                setSortCriteria={(criteria) => dispatch(setWinesSortCriteria(criteria, targetFilters))}
            />

            <SortCriteria
                title="Arvio"
                sortCriteria="review"
                activeSortCriteria={sortCriteria}
                setSortCriteria={(criteria) => dispatch(setWinesSortCriteria(criteria, targetFilters))}
            />

            <SortCriteria
                title="Hinta"
                sortCriteria="price"
                activeSortCriteria={sortCriteria}
                setSortCriteria={(criteria) => dispatch(setWinesSortCriteria(criteria, targetFilters))}
            />

            <SortCriteria
                title="Nimi"
                sortCriteria="name"
                activeSortCriteria={sortCriteria}
                setSortCriteria={(criteria) => dispatch(setWinesSortCriteria(criteria, targetFilters))}
            />
        </React.Fragment>
    );

    return (
        <div>
            <ListWithFilters
                title={`Arvioni - ${selectedCategoryText}`}
                sortContent={renderSortContent()}
            />
        </div>
    )
};

export default UserReviewsPage