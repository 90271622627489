import WineListItem from "../wine-list-item/wine-list-item";
import React from "react";

const ListContent = props => {
    const {data} = props;

    return (
        <div className="wines-container">
            {data.map((item, key) => <WineListItem wine={item} key={key} isInGrid={true}/>)}
        </div>
    )
};

export default ListContent