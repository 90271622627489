import _ from 'lodash';
import {sortWines} from "../helpers/helpers";

const initialReviewsSortCriteria = 'reviewDateDesc';
const initialBookmarksSortCriteria = 'bookmarkDateDesc';

export const userInitialState = {
    loggedIn: false,
    userId: window.localStorage && window.localStorage.getItem('userId'),
    reviews: [],
    bookmarks: []
};

const getWinesFromReviews = (reviews, wines) => {
    const uniqueWineIds = _.uniqBy(reviews, 'wineId').map(wine => wine.wineId);

    return _.compact(uniqueWineIds.map(id => {
        let wine = wines.find(wine => wine.id === id);
        if (wine) {
            const rev = reviews.filter(review => review.wineId === wine.id)[0];
            wine.review = rev;
            return wine
        } else {
            return null
        }
    }));
};

const getWinesFromBookmarks = (bookmarks, wines) => {
    // eslint-disable-next-line array-callback-return
    const bookmarksArr = bookmarks.map(bookmark => {
        const result = wines.find(wine => wine.id === bookmark.id);

        if (result) {
            result.bookmarkDate = bookmark.date;
        }

        if (result) {
            return result
        }
    });

    return _.compact(bookmarksArr);
};

export default function (state = userInitialState, action) {
    switch (action.type) {
        case "LOGIN_USER":
            return {
                ...state,
            };

        case "SET_USER_LOGGED_IN":
            return {
                ...state,
                loggedIn: true,
                userId: action.userId,
            };

        case "SET_USER_LOGGED_OUT":
            return {
                ...state,
                loggedIn: false,
                userId: null,
            };

        case "SET_USER_REVIEWS":
            return {
                ...state,
                reviews: getWinesFromReviews(action.reviews, action.wines)
            };

        case "SET_USER_BOOKMARKS":
            return {
                ...state,
                bookmarks: getWinesFromBookmarks(action.bookmarks, action.wines)
            };

        case "SET_USER_DATA":
            return {
                ...state,
                reviews: sortWines(getWinesFromReviews(action.reviews, action.wines), initialReviewsSortCriteria),
                bookmarks: sortWines(getWinesFromBookmarks(action.bookmarks, action.wines), initialBookmarksSortCriteria)
            };

        default:
            return state
    }
}