import React, { useEffect, useState, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { closeMenus } from "../../state/actions";
import InfiniteScroll from "react-infinite-scroller";
import {
  useFilters,
  useHasFiltersChanged,
  useLocationHasChanged,
  useWines,
} from "../../helpers/hooks";
import Filters from "../filters/filters";
import ListContent from "./list-content";
import {
  countActiveFilters,
  getWinesFromSearchCriteria,
} from "../../helpers/helpers";
import ButtonToggleFilters from "../button-toggle-filters/button-toggle-filters";

const batchSize = 20;

const ListWithFilters = ({ sortContent, title, wines }) => {
  const listContent = wines;
  const currentFilters = useFilters();
  const { searchCriteria } = currentFilters;
  const total = countActiveFilters(currentFilters);
  const locationChanged = useLocationHasChanged();
  const filtersChanged = useHasFiltersChanged();
  const dispatch = useDispatch();
  const [itemsToShow, setItemsToShow] = useState(
    listContent.slice(0, batchSize)
  );
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const { filtersOpen } = useSelector((state) => state.common, shallowEqual);
  const [hasResults, setHasResults] = useState(false);

  useEffect(() => {
    if (listContent.length > 0 && !hasResults) {
      setItemsToShow(listContent.slice(0, batchSize));
      setHasResults(true);
    }

    if (locationChanged || filtersChanged) {
      if (listContent.length === 0) {
        setItemsToShow([]);
      }
      setHasMore(true);
      setHasResults(false);
    }
  }, [
    listContent,
    hasResults,
    setItemsToShow,
    setHasResults,
    locationChanged,
    filtersChanged,
  ]);

  const listTitle = searchCriteria.length > 0 ? "Hakutulokset" : title;

  return useMemo(() => {
    const loadMore = () => {
      const hasFullPageToLoad =
        listContent.length - itemsToShow.length >= batchSize;
      const hasLoadedAll = listContent.length === itemsToShow.length;

      if (listContent.length === 0) {
        return;
      }

      if (hasFullPageToLoad && !hasLoadedAll) {
        const items = listContent.slice(0, batchSize * (page + 1));
        const newPage = page + 1;

        setItemsToShow(items);
        setPage(newPage);
      } else {
        setItemsToShow(listContent);
        setHasMore(false);
      }
    };

    return (
      <div className="list-container">
        <div className={`filters ${filtersOpen ? "visible" : "hidden"}`}>
          <Filters />
        </div>

        <div className="list scroll-container">
          <h3 className="wine-list-title">
            {listTitle} <span className="amount">({listContent.length})</span>
            <ButtonToggleFilters total={total} />
          </h3>

          <div className="sort">{sortContent}</div>

          <InfiniteScroll
            pageStart={0}
            loadMore={() => loadMore()}
            hasMore={hasMore}
          >
            <ListContent data={itemsToShow} />
          </InfiniteScroll>
        </div>

        <ButtonToggleFilters total={total} />

        <button
          className={`display-wines-button ${
            filtersOpen ? "visible" : "hidden"
          }`}
          onClick={() => dispatch(closeMenus())}
        >
          Näytä viinit: {listContent.length}
        </button>
      </div>
    );
  }, [
    itemsToShow,
    filtersOpen,
    dispatch,
    hasMore,
    listTitle,
    sortContent,
    total,
    page,
    listContent,
  ]);
};

const MemoizedListWithFilters = ({ sortContent, title }) => {
  const wines = useWines();
  const { searchCriteria } = useFilters();

  const winesToDisplay = useMemo(
    () =>
      searchCriteria.length > 0
        ? getWinesFromSearchCriteria(searchCriteria, wines)
        : wines,
    [searchCriteria, wines]
  );

  return useMemo(() => {
    return (
      <ListWithFilters
        sortContent={sortContent}
        title={title}
        wines={winesToDisplay}
      />
    );
  }, [wines, sortContent, title]);
};

export default MemoizedListWithFilters;
