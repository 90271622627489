import React from 'react';

const InformationPage = () => {
    return (
        <div className="static-page">
            <h1>Tietoa sivustosta</h1>
            <p>Sivusto on yksityishenkilön ylläpitämä harrasteprojekti ilman kaupallisia tarkoitusperiä.</p>
            <p>Sivuston pääasiallisena tarkoituksena on mahdollistaa viinien arviointi ja merkitseminen muistilistalle.</p>
            <p>Sivuston ylläpitäjään voi olla yhteydessä sähköpostitse: <a href="mailto:aki.narhi@reaktor.com">aki.narhi@reaktor.com</a></p>
            <p>Terveisin,<br />Aki Närhi</p>
        </div>
    )
};

export default InformationPage