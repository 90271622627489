import React from "react";
import {FaStar} from "react-icons/all";

export function StarsTable(props) {
    const {allReviews} = props;
    const getRatings = rating => allReviews.filter(review => review.rating === rating);
    const getClassNameByRating = rating => {
        return rating > 0 ? 'has-reviews' : 'no-reviews'
    };

    return (
        <div className="stars-table">
            <div className="star-row">
                <div className={`stars ${getClassNameByRating(getRatings(5).length)}`}>
                    <FaStar/><FaStar/><FaStar/><FaStar/><FaStar/>
                </div>
                <div className={`rating ${getClassNameByRating(getRatings(5).length)}`}>
                    {getRatings(5).length}
                </div>
            </div>
            <div className="star-row">
                <div className={`stars ${getClassNameByRating(getRatings(4).length)}`}>
                    <FaStar/><FaStar/><FaStar/><FaStar/>
                </div>
                <div className={`rating ${getClassNameByRating(getRatings(4).length)}`}>
                    {getRatings(4).length}
                </div>
            </div>
            <div className="star-row">
                <div className={`stars ${getClassNameByRating(getRatings(3).length)}`}>
                    <FaStar/><FaStar/><FaStar/>
                </div>
                <div className={`rating ${getClassNameByRating(getRatings(3).length)}`}>
                    {getRatings(3).length}
                </div>
            </div>
            <div className="star-row">
                <div className={`stars ${getClassNameByRating(getRatings(2).length)}`}>
                    <FaStar/><FaStar/>
                </div>
                <div className={`rating ${getClassNameByRating(getRatings(2).length)}`}>
                    {getRatings(2).length}
                </div>
            </div>
            <div className="star-row">
                <div className={`stars ${getClassNameByRating(getRatings(1).length)}`}>
                    <FaStar/>
                </div>
                <div className={`rating ${getClassNameByRating(getRatings(1).length)}`}>
                    {getRatings(1).length}
                </div>
            </div>
        </div>
    )
}

export function SopiiBar(props) {
    const {allReviews, sopii} = props;

    return (
        <div className="sopii-wrapper">
            <div className="sopii-bar-container">
                <div style={{'width': `${Math.round((sopii / allReviews.length) * 100)}%`}}/>
            </div>
            <div>
                {sopii === 0 ?
                    `0%`
                    :
                    `${Math.round((sopii / allReviews.length) * 100)}%`
                }
            </div>
        </div>
    )
}

/**
 * @return {null}
 */
export function TableRow(props) {
    const {data, title, description} = props;

    if (!data) {
        return null
    }

    return (
        <div className="table-row">
            <div className="table-cell">{title}</div>
            <div className="table-cell">{data} {description && description}</div>
        </div>
    )
}

export function TabbedContent(props) {
    return (
        <div className="tabbed-content">
            {props.children}
        </div>
    )
}

export function Tabs(props) {
    return (
        <div className="tabs">
            {props.children}
        </div>
    )
}

export function Tab(props) {
    const {isActive, handleClick, title} = props;

    return (
        <div className={`tab ${isActive ? 'active' : ''}`} onClick={() => handleClick()}>
            {title}
        </div>
    )
}

export function TabContent(props) {
    return (
        <div className="tab-content">
            {props.children}
        </div>
    )
}