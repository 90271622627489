import React, { useEffect } from "react";
import LeaveOrEditReview from "./leave-or-edit-review";
import { useWine } from "../../helpers/hooks";
import { WineDetailsContent } from "../wine-list-item/wine-components";
import ReviewsTable from "./reviews-table";
import AllCommentsList from "./all-comments-list";
import { Helmet } from "react-helmet/es/Helmet";
import SimilarWines from "./similarWines";

const WineDetails = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  });

  const wine = useWine();

  if (!wine) {
    return null;
  }

  return (
    <div className="wine-details">
      <Helmet title={`${wine.name} - Kanpai`} description={wine.description} />

      <div className="wine-column-container">
        <div className="wine-column main-details-column">
          <WineDetailsContent />
          <LeaveOrEditReview />
          <ReviewsTable />
          <AllCommentsList />
        </div>
        <div className="wine-column similar-wines-column">
          <SimilarWines />
        </div>
      </div>
    </div>
  );
};

export default WineDetails;
