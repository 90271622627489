import {initialState} from "./index";

export const wineDetailsInitialState = {
    comments: [],
    reviews: []
};

export default function (state = wineDetailsInitialState, action) {
    switch (action.type) {
        case "SET_COMMENTS":
            return {
                ...state,
                comments: action.comments
            };

        case "SET_CURRENT_WINE_REVIEWS":
            return {
                ...state,
                reviews: action.reviews
            };

        case "RESET_WINE_DETAILS":
            return initialState;

        default:
            return state
    }
}