import React from "react";
import {NavLink} from "react-router-dom";
import LoginButton from "../login-button";

const AssistiveLinks = () => {
    return (
        <div className="assistive-links">
            <LoginButton/>
            <NavLink className="assistive-link" to="/information">Tietoa</NavLink>
            <NavLink className="assistive-link" to="/terms">Käyttöehdot</NavLink>
            {/*<a className="assistive-link" href="#">Palaute</a>*/}
        </div>
    )
};

export default AssistiveLinks;