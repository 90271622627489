import React from "react";
import { Link } from "react-router-dom";
import { FaBookmark } from "react-icons/all";
import {
  WineDescriptionLabel,
  WineHeader,
  WineIcons,
  WineImage,
  WinePrice,
  WineScore,
  WineSelection
} from "./wine-components";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { searchWines } from "../../state/actions";

const WineListItem = props => {
  const { wine, isInGrid, isMatchingWine } = props;
  const { bookmarks } = useSelector(state => state.user, shallowEqual);
  const bookmarkIds = bookmarks && bookmarks.map(bookmark => bookmark.id);
  const isBookmarkedByMe = bookmarkIds.includes(wine.id);

  const dispatch = useDispatch();

  const handleListItemClick = () => {
    dispatch(searchWines(""));
  };

  if (isInGrid) {
    return (
      <Link to={`/wine/${wine.id}`} className="wine-list-item wine-grid-item">
        {isBookmarkedByMe && <FaBookmark className="bookmark-icon" />}
        <WineSelection selection={wine.selection} />
        <WineHeader wine={wine} />

        <WineImage wine={wine} />

        <div className="footer">
          <div className="label-container">
            <WineDescriptionLabel wine={wine} />
            <WineScore wine={wine} />
          </div>

          <WinePrice wine={wine} />
        </div>

        <WineIcons wine={wine} />
      </Link>
    );
  } else {
    return (
      <Link
        to={`/wine/${wine.id}`}
        className="wine-list-item wine-search-item"
        onClick={() => handleListItemClick()}
      >
        {isBookmarkedByMe && <FaBookmark className="bookmark-icon" />}

        <div className="row">
          <div className="image-container">
            <WineImage wine={wine} />
          </div>
          <div className="text-container">
            <WineHeader wine={wine} />
            <WineSelection selection={wine.selection} />
            <WineDescriptionLabel wine={wine} />
            <WineScore wine={wine} />
            <WinePrice wine={wine} />
          </div>
        </div>

        <WineIcons wine={wine} />

        {isMatchingWine && process.env.REACT_APP_DISPLAY_MATCHING_WINES_DATA && (
          <div>
            <div>Maa: {wine.matchingDetails.country}</div>
            <div>Alue: {wine.matchingDetails.region}</div>
            <div>Kuvaus: {wine.matchingDetails.description}</div>
            <div>Rypäleet: {wine.matchingDetails.grapes}</div>
            <div>Alkoholi: {wine.matchingDetails.alcohol}</div>
            <div>Sokeri: {wine.matchingDetails.sugar}</div>
            <div>Hapot: {wine.matchingDetails.acids}</div>
          </div>
        )}
      </Link>
    );
  }
};

export default WineListItem;
