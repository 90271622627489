import React, {useEffect} from "react";
import {GoogleLogin} from "react-google-login";
import {useDispatch, useSelector} from "react-redux";
import {setUserLoggedIn, setUserLoggedOut, setUserData} from "../state/actions";

const LoginButton = () => {
    const loggedIn = useSelector(state => state.user.loggedIn);
    const dispatch = useDispatch();
    const {hasFetchedWines} = useSelector(state => state.wines);

    useEffect(() => {
        if (!loggedIn && hasFetchedWines) {
            tryLogin();
        }
    });

    const tryLogin = () => {
        const existingLoginId = window.localStorage.getItem('userId');

        if (existingLoginId) {
            fetchUserData(existingLoginId)
        }
    };

    const fetchUserData = userId => {
        fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/user/${userId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(dispatch(setUserLoggedIn(userId)))
            .then(userData => dispatch(setUserData(userData.reviews, userData.bookmarks)));
    };

    const googleLogout = event => {
        event.preventDefault();
        const auth2 = window.gapi.auth2.getAuthInstance();
        const logout = () => dispatch(setUserLoggedOut());

        auth2.signOut().then(() => {
            logout()
        });
    };

    const handleGoogleLogin = user => {
        const id_token = user.getAuthResponse().id_token;
        validateUserData(id_token)
    };

    const validateUserData = token => {
        fetch(`https://oauth2.googleapis.com/tokeninfo?id_token=${token}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(userData => {
                fetchUserData(userData.sub);
                window.localStorage.setItem('userId', userData.sub)
            });
    };

    return (
        loggedIn ?
            // eslint-disable-next-line
            <a className="assistive-link" onClick={event => googleLogout(event)} href="#">
                Kirjaudu ulos
            </a>
            :
            <GoogleLogin onSuccess={handleGoogleLogin} onFailure={handleGoogleLogin}
                         clientId={"1081416647857-2aujij6frksu86pec519nc2b4ne76crk.apps.googleusercontent.com"}/>

    )
};

export default LoginButton