import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app';
import multi from 'redux-multi'
import {Provider} from "react-redux"
import {compose, createStore, applyMiddleware} from "redux"
import reducers, { initialState } from "./state"

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
    reducers,
    initialState,
    composeEnhancers(applyMiddleware(multi))
);

const Base = () => (
    <Provider store={store}>
        <App/>
    </Provider>
);

ReactDOM.render(<Base/>, document.getElementById('root'));
