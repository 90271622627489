import React from "react";
import {setSelectedWinesCategory} from "../../state/actions";
import {CATEGORY_RED_WINES, CATEGORY_ROSE_WINES, CATEGORY_SPARKLING_WINES, CATEGORY_WHITE_WINES} from "../../helpers/helpers";
import RadioButtonRow from "./radio-button-row";
import {useDispatch} from "react-redux";
import FilterGroup from "./filter-group";
import {useFilters, useTargetFilters} from "../../helpers/hooks";

const FilterByType = () => {
    const dispatch = useDispatch();
    const targetFilters = useTargetFilters();
    const {selectedWinesCategory} = useFilters();

    return (
        <FilterGroup title="Tyyppi">
            <RadioButtonRow value="Kaikki" group="winetype" isSelected={selectedWinesCategory === 'all'} onChangeFn={() => dispatch(setSelectedWinesCategory('all', targetFilters))}/>
            <RadioButtonRow value="Punaviinit" group="winetype" isSelected={selectedWinesCategory === CATEGORY_RED_WINES} onChangeFn={() => dispatch(setSelectedWinesCategory(CATEGORY_RED_WINES, targetFilters))}/>
            <RadioButtonRow value="Valkoviinit" group="winetype" isSelected={selectedWinesCategory === CATEGORY_WHITE_WINES} onChangeFn={() => dispatch(setSelectedWinesCategory(CATEGORY_WHITE_WINES, targetFilters))}/>
            <RadioButtonRow value="Kuohuviinit & samppanjat" group="winetype" isSelected={selectedWinesCategory === CATEGORY_SPARKLING_WINES} onChangeFn={() => dispatch(setSelectedWinesCategory(CATEGORY_SPARKLING_WINES, targetFilters))}/>
            <RadioButtonRow value="Roseeviinit" group="winetype" isSelected={selectedWinesCategory === CATEGORY_ROSE_WINES} onChangeFn={() => dispatch(setSelectedWinesCategory(CATEGORY_ROSE_WINES, targetFilters))}/>
        </FilterGroup>
    )
};

export default FilterByType