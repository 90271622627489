import _ from "lodash";

export const commonInitialState = {
    menuOpen: false,
    filtersOpen: false,
    reviewMap: {},
    expandedFilters: []
};

export default function (state = commonInitialState, action) {
    switch (action.type) {
        case "OPEN_MENU":
            return {
                ...state,
                menuOpen: true
            };

        case "CLOSE_MENU":
            return {
                ...state,
                menuOpen: false
            };

        case "TOGGLE_MENU":
            return {
                ...state,
                menuOpen: !state.menuOpen,
                filtersOpen: false
            };

        case "OPEN_FILTERS":
            return {
                ...state,
                filtersOpen: true
            };

        case "CLOSE_FILTERS":
            return {
                ...state,
                menuOpen: false,
                filtersOpen: false
            };

        case "TOGGLE_FILTERS":
            return {
                ...state,
                filtersOpen: !state.filtersOpen,
                menuOpen: false
            };

        case "CLOSE_MENUS": {
            return {
                ...state,
                menuOpen: false,
                filtersOpen: false
            }
        }

        case "ADD_EXPANDED_FILTER": {
            return {
                ...state,
                expandedFilters: [...state.expandedFilters, action.expandedFilter]
            }
        }

        case "REMOVE_EXPANDED_FILTER": {
            return {
                ...state,
                expandedFilters: _.without(state.expandedFilters, action.expandedFilter)
            }
        }

        default:
            return state
    }
}