import React, {Component} from 'react';
import {Route, withRouter} from 'react-router-dom'
import HomePage from "./pages/home-page/home-page";
import UserReviewsPage from "./pages/user-reviews-page/user-reviews-page"
import UserBookmarksPage from "./pages/user-bookmarks-page/user-bookmarks-page"
import Wine from './components/wine-details/wine-details'
import PrivacyPage from "./pages/privacy-page/privacy-page"
import TermsPage from "./pages/terms-page/terms-page";
import InformationPage from "./pages/information-page/information-page";

class Routes extends Component {
    render() {
        return (
            <div className="page-content">
                <Route exact path="/" component={HomePage}/>
                <Route exact path="/wine/:id" component={Wine}/>
                <Route exact path="/user/reviews" component={UserReviewsPage}/>
                <Route exact path="/user/bookmarks" component={UserBookmarksPage}/>

                <Route exact path="/information" component={InformationPage}/>
                <Route exact path="/terms" component={TermsPage}/>

                <Route exact path="/privacy" component={PrivacyPage}/>
            </div>
        )
    }
}

export default withRouter(Routes)