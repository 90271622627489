import React from "react";
import {FaStar, FaRegStar} from 'react-icons/fa';

const ReviewWithStars = props => {
    const {rating, onChange} = props;
    const fiveOutFiveClassName = `highlight ${rating === 5 ? 'five-out-five' : ''}`;

    const renderStarWithRating = ratingToSave => {
        const click = () => onChange(ratingToSave);

        if (rating >= ratingToSave) {
            return <div className="star"><FaStar className={fiveOutFiveClassName} onClick={click}/></div>
        } else {
            return <div className="star"><FaRegStar onClick={click}/></div>
        }
    };

    return (
        <div className="review-stars">
            <div className="stars-row">
                <div className="stars-container">
                    {renderStarWithRating(1)}
                    {renderStarWithRating(2)}
                    {renderStarWithRating(3)}
                    {renderStarWithRating(4)}
                    {renderStarWithRating(5)}
                </div>
                <div className="amount">
                    {rating} / 5
                </div>
            </div>
        </div>
    )
};

export default ReviewWithStars