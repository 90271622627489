import FilterGroup from "./filter-group";
import React from "react";
import {useDispatch} from "react-redux";
import {setPriceMax, setPriceMin} from "../../state/actions";
import {useFilters, useTargetFilters} from "../../helpers/hooks";

const FilterByPrice = () => {
    const dispatch = useDispatch();
    const targetFilters = useTargetFilters();
    const {priceMin, priceMax} = useFilters();

    return (
        <FilterGroup title="Hinta">
            <div className="price-inputs">
                <input type="tel" placeholder="Min" value={priceMin} onChange={e => dispatch(setPriceMin(e.target.value, targetFilters))}/>
                <input type="tel" placeholder="Max" value={priceMax} onChange={e => dispatch(setPriceMax(e.target.value, targetFilters))}/>
            </div>
        </FilterGroup>
    )
};

export default FilterByPrice