import React, {useEffect, useState} from "react";
import {SopiiBar, StarsTable} from "./wine-details-components";
import {useWine} from "../../helpers/hooks";
import {useDispatch, useSelector} from "react-redux";
import {fetchReviewsById} from "../../helpers/fetches";
import {setCurrentWineReviews} from "../../state/actions";
import {FaUserFriends, IoIosWine} from "react-icons/all";

const ReviewsTable = () => {
    const wine = useWine();
    const dispatch = useDispatch();
    const [hasFetchedData, setHasFetchedData] = useState(false);
    const {reviews} = useSelector(state => state.wineDetails);

    useEffect(() => {
        if (!hasFetchedData) {
            setHasFetchedData(true);

            fetchReviewsById(wine.id).then(reviews => {
                dispatch(setCurrentWineReviews(reviews))
            });
        }
    }, [hasFetchedData, setHasFetchedData, dispatch, wine]);

    if (reviews.length === 0) {
        return null
    }

    const goodForCompanyLength = reviews.filter(review => !!review.goodForCompany).length;
    const goodAsIsLength = reviews.filter(review => !!review.goodAsIs).length;

    return (
        <div className="review-block">
            <h3>Arviot ({reviews.length})</h3>

            <div className="review-tables-container">
                <div className="stars-table">
                    <StarsTable allReviews={reviews}/>
                </div>

                <div className="bars-table">
                    <h4><IoIosWine/> Nautiskeluun</h4>
                    <SopiiBar allReviews={reviews} sopii={goodAsIsLength}/>

                    <h4><FaUserFriends/> Seurusteluun</h4>
                    <SopiiBar allReviews={reviews} sopii={goodForCompanyLength}/>
                </div>
            </div>
        </div>
    )
};

export default ReviewsTable