import React, {Component} from "react";

class SortCriteria extends Component {
    handleClick = () => {
        const {
            setSortCriteria,
            sortCriteria,
            activeSortCriteria
        } = this.props;
        setSortCriteria(activeSortCriteria === `${sortCriteria}Asc` ? `${sortCriteria}Desc` : `${sortCriteria}Asc`)
    };

    render() {
        const {
            title,
            sortCriteria,
            activeSortCriteria,
        } = this.props;

        return (
            <div
                className={activeSortCriteria === `${sortCriteria}Asc` || activeSortCriteria === `${sortCriteria}Desc` ? 'active' : ''}
                onClick={this.handleClick}
            >
                {title}
            </div>
        )
    }
}

export default SortCriteria