import FilterGroup from "./filter-group";
import React from "react";
import RadioButtonRow from "./radio-button-row";
import {useDispatch} from "react-redux";
import {setWinesSelection} from "../../state/actions";
import {useFilters, useTargetFilters} from "../../helpers/hooks";

const FilterBySelection = () => {
    const dispatch = useDispatch();
    const {selectedSelection} = useFilters();
    const targetFilters = useTargetFilters();

    const renderRadiobuttonRow = title => <RadioButtonRow value={title} isSelected={selectedSelection === title.toLowerCase()} onChangeFn={() => dispatch(setWinesSelection(title.toLowerCase(), targetFilters))}/>

    return (
        <FilterGroup title="Valikoima">
            <RadioButtonRow value="Kaikki" isSelected={selectedSelection === 'all'} onChangeFn={() => dispatch(setWinesSelection('all', targetFilters))}/>
            {renderRadiobuttonRow('Vakiovalikoima')}
            {renderRadiobuttonRow('Tilausvalikoima')}
            {renderRadiobuttonRow('Erikoiserä')}
        </FilterGroup>
    )
};

export default FilterBySelection