import { useRef } from "react";
import { useParams, useRouteMatch, useLocation } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { setComments } from "../state/actions";
import {
  filterWinesByFilters,
  getCountries,
  getDescriptions,
  getGrapes,
  getSpecialGroups,
  getWinesFromSearchCriteria,
} from "./helpers";
import {
  userBookmarksInitialFilters,
  userReviewsInitialFilters,
  winesInitialFilters,
} from "../state/filtersReducer";
import { fetchReviewsById } from "./fetches";

export const useWine = () => {
  const params = useParams();
  const id = params.id;
  const { wines } = useSelector((state) => state.wines);
  const findWineById = (id) => wines.find((wine) => wine.id === id);
  return findWineById(id);
};

export const useReviewsForCurrentWine = () => {
  const params = useParams();
  const id = params.id;
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    fetchReviewsById(id).then((reviews) => setReviews(reviews));
  }, [id]);

  return reviews;
};

export const useCommentsForCurrentWine = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const id = params.id;
  const userId = useSelector((state) => state.user.userId);
  const comments = useSelector((state) => state.wineDetails.comments);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/comments/${id}`)
      .then((res) => res.json())
      .then((comments) => dispatch(setComments(comments)));
  }, [id, userId, dispatch]);

  return comments;
};

export const useCurrentWineIsReviewedByCurrentUser = () => {
  const wine = useWine();
  const reviews = useSelector((state) => state.user.reviews);
  return wine && reviews && reviews.map((wine) => wine.id).includes(wine.id);
};

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useWines = () => {
  const match = useRouteMatch();
  const isReviewsPage = match.path === "/user/reviews";
  const isBookmarksPage = match.path === "/user/bookmarks";
  const { wines, hasFetchedWines } = useSelector(
    (state) => state.wines,
    shallowEqual
  );
  const { reviews, bookmarks } = useSelector(
    (state) => state.user,
    shallowEqual
  );
  const filters = useFilters();

  let winesToUse;

  if (isReviewsPage) {
    winesToUse = reviews;
  } else if (isBookmarksPage) {
    winesToUse = bookmarks;
  } else {
    winesToUse = wines;
  }

  if (hasFetchedWines) {
    return filterWinesByFilters(winesToUse, filters);

    if (filters.searchCriteria.length > 0) {
      return getWinesFromSearchCriteria(filters.searchCriteria, wines);
    } else {
      return filterWinesByFilters(winesToUse, filters);
    }
  } else {
    return [];
  }
};

export const useHasFiltersChanged = () => {
  const filters = useFilters();
  const previousFilters = usePrevious(filters);

  return filters !== previousFilters;
};

export const useLocationHasChanged = () => {
  const path = useLocation().pathname;
  const previousPath = usePrevious(path);

  return path !== previousPath;
};

export const useCountries = () => {
  const wines = useWines();
  const result = getCountries(wines);
  const { selectedCountries } = useFilters();
  const hasFiltersChanged = useHasFiltersChanged();
  const [countries, setCountries] = useState(result);
  const [hasResult, setHasResult] = useState(false);

  useEffect(() => {
    if (!hasResult && result.length > 0) {
      setCountries(result);
      setHasResult(true);
    } else if (hasFiltersChanged && selectedCountries.length === 0) {
      setCountries(result);
    }
  }, [
    hasResult,
    result,
    setCountries,
    setHasResult,
    selectedCountries,
    hasFiltersChanged,
  ]);

  return countries;
};

export const useGrapes = () => {
  const wines = useWines();
  const result = getGrapes(wines);
  const { selectedGrapes } = useFilters();
  const hasFiltersChanged = useHasFiltersChanged();
  const [grapes, setGrapes] = useState(result);
  const [hasResult, setHasResult] = useState(false);

  useEffect(() => {
    if (!hasResult && result.length > 0) {
      setGrapes(result);
      setHasResult(true);
    } else if (hasFiltersChanged && selectedGrapes.length === 0) {
      setGrapes(result);
    }
  }, [
    hasResult,
    result,
    setGrapes,
    setHasResult,
    selectedGrapes,
    hasFiltersChanged,
  ]);

  return grapes;
};

export const useDescriptions = () => {
  const wines = useWines();
  const result = getDescriptions(wines);
  const { selectedDescriptions } = useFilters();
  const hasFiltersChanged = useHasFiltersChanged();
  const [descriptions, setDescriptions] = useState(result);
  const [hasResult, setHasResult] = useState(false);

  useEffect(() => {
    if (!hasResult && result.length > 0) {
      setDescriptions(result);
      setHasResult(true);
    } else if (hasFiltersChanged && selectedDescriptions.length === 0) {
      setDescriptions(result);
    }
  }, [
    hasResult,
    result,
    setDescriptions,
    setHasResult,
    selectedDescriptions,
    hasFiltersChanged,
  ]);

  return descriptions;
};

export const useSpecialGroups = () => {
  const wines = useWines();
  const result = getSpecialGroups(wines);
  const { selectedSpecialGroups } = useFilters();
  const hasFiltersChanged = useHasFiltersChanged();
  const [specialGroups, setSpecialGroups] = useState(result);
  const [hasResult, setHasResult] = useState(false);

  useEffect(() => {
    if (!hasResult && result.length > 0) {
      setSpecialGroups(result);
      setHasResult(true);
    } else if (hasFiltersChanged && selectedSpecialGroups.length === 0) {
      setSpecialGroups(result);
    }
  }, [
    hasResult,
    result,
    setSpecialGroups,
    setHasResult,
    selectedSpecialGroups,
    hasFiltersChanged,
  ]);

  return specialGroups;
};

export const useFilters = () => {
  const match = useRouteMatch();
  const isReviewsPage = match.path === "/user/reviews";
  const isBookmarksPage = match.path === "/user/bookmarks";

  const {
    winesFilters,
    userReviewsFilters,
    userBookmarksFilters,
  } = useSelector((state) => state.filters, shallowEqual);

  if (isReviewsPage) {
    return userReviewsFilters;
  } else if (isBookmarksPage) {
    return userBookmarksFilters;
  } else {
    return winesFilters;
  }
};

export const useInitialFilters = () => {
  const match = useRouteMatch();
  const isReviewsPage = match.path === "/user/reviews";
  const isBookmarksPage = match.path === "/user/bookmarks";

  if (isReviewsPage) {
    return userReviewsInitialFilters;
  } else if (isBookmarksPage) {
    return userBookmarksInitialFilters;
  } else {
    return winesInitialFilters;
  }
};

export const useTargetFilters = () => {
  const match = useRouteMatch();
  const isReviewsPage = match.path === "/user/reviews";
  const isBookmarksPage = match.path === "/user/bookmarks";

  if (isReviewsPage) {
    return "reviews";
  } else if (isBookmarksPage) {
    return "bookmarks";
  } else {
    return "wines";
  }
};
