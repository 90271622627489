import FilterRow from "../filter-row/filter-row";
import React from "react";
import {addFilterSpecialGroup, removeFilterSpecialGroup} from "../../state/actions";
import {useFilters, useSpecialGroups, useTargetFilters} from "../../helpers/hooks";
import {useDispatch} from "react-redux";
import FilterGroup from "./filter-group";

const FilterBySpecialGroup = () => {
    const dispatch = useDispatch();
    const {selectedSpecialGroups} = useFilters();
    const targetFilters = useTargetFilters();
    const specialGroups = useSpecialGroups();
    const currentSpecialGroupIsSelected = specialGroup => selectedSpecialGroups.includes(specialGroup);
    const handleSpecialGroupClick = specialGroup => currentSpecialGroupIsSelected(specialGroup) ? dispatch(removeFilterSpecialGroup(specialGroup, targetFilters)) : dispatch(addFilterSpecialGroup(specialGroup, targetFilters));

    return (
        <FilterGroup title="Erityisryhmä">
            {specialGroups.map((specialGroup, key) => (
                <FilterRow
                    key={key}
                    title={specialGroup.specialGroup}
                    amount={specialGroup.amount}
                    isSelected={currentSpecialGroupIsSelected(specialGroup.specialGroup)}
                    clickFn={() => handleSpecialGroupClick(specialGroup.specialGroup)}
                />
            ))}
        </FilterGroup>
    )
};

export default FilterBySpecialGroup