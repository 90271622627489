import FilterRow from "../filter-row/filter-row";
import React, {useState} from "react";
import {useFilters, useGrapes, useTargetFilters} from "../../helpers/hooks";
import {limitsForCurrentCategory, searchGrapesByCriteria} from "../../helpers/helpers";
import {addFilterGrape, removeFilterGrape} from "../../state/actions";
import {useDispatch} from "react-redux";
import FilterGroup from "./filter-group";

const FilterByGrape = () => {
    const dispatch = useDispatch();
    const targetFilters = useTargetFilters();
    const [grapesExpanded, setGrapesExpanded] = useState(false);
    const [grapesSearchTerm, setGrapesSearchTerm] = useState('');
    const grapes = useGrapes();

    const {selectedGrapes} = useFilters();

    const grapesToUse = grapesSearchTerm === '' ? grapes : searchGrapesByCriteria(grapesSearchTerm, grapes);
    const grapesToRender = grapesExpanded ? grapesToUse : grapesToUse.slice(0, limitsForCurrentCategory().grapes);

    const currentGrapeIsSelected = grape => selectedGrapes.includes(grape);
    const handleGrapeFilterClick = grape => currentGrapeIsSelected(grape) ? dispatch(removeFilterGrape(grape, targetFilters)) : dispatch(addFilterGrape(grape, targetFilters));

    return (
        <FilterGroup title="Tyyppi" amount={grapes.length}>
            <div className="input-filter-container">
                <input placeholder="Etsi rypäle"
                       onChange={(event) => setGrapesSearchTerm(event.target.value)}
                       value={grapesSearchTerm}/>
            </div>

            {grapesToRender.map((grape, key) => (
                <FilterRow
                    key={key}
                    title={grape.grape}
                    amount={grape.amount}
                    isSelected={currentGrapeIsSelected(grape.grape)}
                    clickFn={() => handleGrapeFilterClick(grape.grape)}
                />
            ))}

            <div className="show-all" onClick={() => setGrapesExpanded(!grapesExpanded)}>
                {grapesExpanded ? 'Piilota' : 'Näytä'} kaikki rypäleet
            </div>
        </FilterGroup>
    )
};

export default FilterByGrape