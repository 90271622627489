import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {setComments} from "../../state/actions";
import {useWine} from "../../helpers/hooks";
import {MdDelete} from "react-icons/all";

const Comment = props => {
    const dispatch = useDispatch();
    const {comment, shouldShowDelete} = props;
    const userId = useSelector(state => state.user.userId);
    const isOwnComment = comment.userId === userId;
    const wine = useWine();

    const deleteComment = commentId => {
        const url = `${process.env.REACT_APP_API_ENDPOINT}/api/comment/delete/${commentId}`;

        const data = {
            userId,
            wineId: wine.id
        };

        fetch(url, {
            method: 'DELETE',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(comments => {
                dispatch(setComments(comments));
            })
    };

    return (
        <blockquote>
            {comment.comment}

            {shouldShowDelete && isOwnComment &&
            <button className="small gray icon-red round-icon" onClick={() => deleteComment(comment._id)}>
                <MdDelete />
            </button>
            }
        </blockquote>
    )
};

export default Comment;