import React from "react";
import {FaFilter} from 'react-icons/fa';
import {toggleFilters} from "../../state/actions";
import {useDispatch} from "react-redux";

const ButtonToggleFilters = ({total}) => {
    const dispatch = useDispatch();

    return (
        <div className={`mobile-menu-button filters-toggle`} onClick={() => dispatch(toggleFilters())}>
            {total !== 0 && <span className="active-filters-amount">{total}</span>}
            <FaFilter/>
        </div>
    )
};

export default ButtonToggleFilters;