export const winesInitialState = {
    wines: [],
    hasFetchedWines: false
};

export default function (state = winesInitialState, action) {
    switch (action.type) {
        case "SET_WINES": {
            return {
                ...state,
                wines: action.wines,
                hasFetchedWines: true
            }
        }

        default:
            return state
    }
};