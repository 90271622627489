import { store } from "../index";
import { saveUserFilterToLocalStorage } from "../helpers/helpers";

export const setUserLoggedIn = (userId, reviews) => {
  window.localStorage && window.localStorage.setItem("userId", userId);

  return {
    type: "SET_USER_LOGGED_IN",
    userId: userId,
    reviews
  };
};

export const setUserLoggedOut = () => {
  window.localStorage.clear();

  return {
    type: "SET_USER_LOGGED_OUT"
  };
};

export const loginUser = () => {
  return {
    type: "LOGIN_USER"
  };
};

export const logoutUser = () => {
  return {
    type: "LOGOUT_USER"
  };
};

export const toggleMenu = () => {
  return {
    type: "TOGGLE_MENU"
  };
};

export const toggleFilters = () => {
  return {
    type: "TOGGLE_FILTERS"
  };
};

export const setUserReviews = reviews => {
  const { wines } = store.getState().wines;

  return {
    type: "SET_USER_REVIEWS",
    reviews,
    wines
  };
};

export const setUserBookmarks = bookmarks => {
  const { wines } = store.getState().wines;

  return {
    type: "SET_USER_BOOKMARKS",
    bookmarks,
    wines
  };
};

export const setUserData = (reviews, bookmarks) => {
  const { wines } = store.getState().wines;

  return {
    type: "SET_USER_DATA",
    reviews,
    bookmarks,
    wines
  };
};

export const searchWines = (criteria, targetFilters) => {
  return {
    type: "SEARCH_WINES",
    criteria,
    targetFilters
  };
};

export const closeMenus = () => {
  return {
    type: "CLOSE_MENUS"
  };
};

export const setComments = comments => {
  return {
    type: "SET_COMMENTS",
    comments
  };
};

export const addExpandedFilter = expandedFilter => {
  return {
    type: "ADD_EXPANDED_FILTER",
    expandedFilter
  };
};

export const removeExpandedFilter = expandedFilter => {
  return {
    type: "REMOVE_EXPANDED_FILTER",
    expandedFilter
  };
};

export const setWinesSortCriteria = (winesSortCriteria, targetFilters) => {
  return {
    type: "SET_WINES_SORT_CRITERIA",
    winesSortCriteria,
    targetFilters
  };
};

export const setSelectedWinesCategory = (
  selectedWinesCategory,
  targetFilters
) => {
  return {
    type: "SET_SELECTED_WINES_CATEGORY",
    selectedWinesCategory: selectedWinesCategory,
    targetFilters
  };
};

export const setWinesSelection = (selection, targetFilters) => {
  return {
    type: "SET_WINES_SELECTION",
    selection,
    targetFilters
  };
};

export const addFilterCountry = (country, targetFilters) => {
  return {
    type: "ADD_FILTER_COUNTRY",
    country,
    targetFilters
  };
};

export const removeFilterCountry = (country, targetFilters) => {
  return {
    type: "REMOVE_FILTER_COUNTRY",
    country,
    targetFilters
  };
};

export const addFilterGrape = (grape, targetFilters) => {
  return {
    type: "ADD_FILTER_GRAPE",
    grape,
    targetFilters
  };
};

export const removeFilterGrape = (grape, targetFilters) => {
  return {
    type: "REMOVE_FILTER_GRAPE",
    grape,
    targetFilters
  };
};

export const addFilterRegion = (region, targetFilters) => {
  return {
    type: "ADD_FILTER_REGION",
    region,
    targetFilters
  };
};

export const removeFilterRegion = (region, targetFilters) => {
  return {
    type: "REMOVE_FILTER_REGION",
    region,
    targetFilters
  };
};

export const addFilterDescription = (description, targetFilters) => {
  return {
    type: "ADD_FILTER_DESCRIPTION",
    description,
    targetFilters
  };
};

export const removeFilterDescription = (description, targetFilters) => {
  return {
    type: "REMOVE_FILTER_DESCRIPTION",
    description,
    targetFilters
  };
};

export const addFilterSpecialGroup = (specialGroup, targetFilters) => {
  return {
    type: "ADD_FILTER_SPECIAL_GROUP",
    specialGroup,
    targetFilters
  };
};

export const removeFilterSpecialGroup = (specialGroup, targetFilters) => {
  return {
    type: "REMOVE_FILTER_SPECIAL_GROUP",
    specialGroup,
    targetFilters
  };
};

export const setFilterBottleSize = (bottleSize, targetFilters) => {
  return {
    type: "SET_FILTER_BOTTLE_SIZE",
    bottleSize: bottleSize,
    targetFilters
  };
};

export const setPriceMin = (priceMin, targetFilters) => {
  return {
    type: "SET_PRICE_MIN",
    priceMin,
    targetFilters
  };
};

export const setPriceMax = (priceMax, targetFilters) => {
  return {
    type: "SET_PRICE_MAX",
    priceMax,
    targetFilters
  };
};

export const resetSelectionFilters = targetFilters => {
  return {
    type: "RESET_SELECTION_FILTERS",
    targetFilters
  };
};

export const setCurrentWineReviews = reviews => {
  return {
    type: "SET_CURRENT_WINE_REVIEWS",
    reviews
  };
};

export const setWines = wines => {
  return {
    type: "SET_WINES",
    wines
  };
};
