export const fetchReviewsById = id => {
    return fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/reviews/${id}`)
        .then(res => res.json())
};

export const fetchWines = () => {
    return fetch(`${process.env.REACT_APP_API_ENDPOINT}/catalogue`)
        .then(res => res.json())
};

export const fetchReviewMap = () => {
    return fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/reviews`)
        .then(res => res.json())
};

export const fetchLatestBuildVersion = () => {
    return fetch(`${process.env.REACT_APP_API_ENDPOINT}/build-version`)
        .then(res => res.json())
};