import React from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {addExpandedFilter, removeExpandedFilter} from "../../state/actions";

const FilterGroup = props => {
    const dispatch = useDispatch();
    const {title, amount} = props;
    const {expandedFilters} = useSelector(state => state.common, shallowEqual);
    const isExpanded = !expandedFilters.includes(title);
    const handleClick = () => isExpanded ? dispatch(addExpandedFilter(title)) : dispatch(removeExpandedFilter(title));

    return (
        <div className="filter-group">
            <h3 className="filter-row-title" onClick={() => handleClick(title)}>
                {title} {amount && <span className="amount">({amount})</span>}
            </h3>

            {isExpanded &&
                <div className="filter-rows-container">
                {props.children}
                </div>
            }
        </div>
    )
};

export default FilterGroup