import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setComments, setCurrentWineReviews, setUserReviews} from "../../state/actions";
import {useCommentsForCurrentWine, useWine} from "../../helpers/hooks";
import ReviewWithStars from "./review-stars";
import BookmarkButton from "./bookmark-button";
import dayjs from "dayjs";
import StarsByRating from "../stars-by-rating/stars-by-rating";
import {IoIosWine, FaUserFriends, FaPlus} from "react-icons/all";
import Comment from "./comment";
import {fetchReviewsById} from "../../helpers/fetches";

const LeaveOrEditReview = () => {
    const initialRating = 0;
    const initialGoodAsIs = false;
    const initialGoodForCompany = false;

    const dispatch = useDispatch();
    const wine = useWine();
    const currentUserReviews = useSelector(state => state.user.reviews);
    const currentWineComments = useCommentsForCurrentWine();
    const isRatedByCurrentUser = currentUserReviews.map(wine => wine.id).includes(wine.id);

    const {userId} = useSelector(state => state.user);

    const currentUserComments = currentWineComments.filter(comment => comment.userId === userId);

    const [isEditingReview, setIsEditingReview] = useState(false);
    const [rating, setRating] = useState(isRatedByCurrentUser ? wine.review.rating : initialRating);
    const [goodAsIs, setGoodAsIs] = useState(isRatedByCurrentUser ? wine.review.goodAsIs : initialGoodAsIs);
    const [goodForCompany, setGoodForCompany] = useState(isRatedByCurrentUser ? wine.review.goodForCompany : initialGoodForCompany);
    const [commentExpanded, setCommentExpanded] = useState(false);

    const [commentIsPublic, setCommentIsPublic] = useState(true);
    const [userComment, setUserComment] = useState('');
    const {review} = wine;

    const fetchReviewsForCurrentWine = () => {
        fetchReviewsById(wine.id).then(reviews => {
            dispatch(setCurrentWineReviews(reviews))
        });
    };

    useEffect(() => {
        if (review) {
            setRating(review.rating);
            setGoodAsIs(review.goodAsIs);
            setGoodForCompany(review.goodForCompany);
        } else {
            setRating(initialRating);
            setGoodAsIs(initialGoodAsIs);
            setGoodForCompany(initialGoodForCompany);
        }
    }, [review, setRating, setGoodAsIs, setGoodForCompany, initialRating, initialGoodAsIs, initialGoodForCompany]);

    const addReview = wineId => {
        const url = `${process.env.REACT_APP_API_ENDPOINT}/api/review/add`;

        const data = {
            userId,
            wineId,
            goodAsIs,
            goodForCompany,
            rating
        };

        fetch(url, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(reviews => {
                dispatch(setUserReviews(reviews));
                fetchReviewsForCurrentWine();
            })
    };

    const updateReview = () => {
        const url = `${process.env.REACT_APP_API_ENDPOINT}/api/review/update`;

        const data = {
            _id: review._id,
            wineId: review.wineId,
            userId,
            goodAsIs,
            goodForCompany,
            rating
        };

        fetch(url, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(reviews => {
                dispatch(setUserReviews(reviews));
                fetchReviewsForCurrentWine();
                setIsEditingReview(false);
            })
    };

    const deleteReview = () => {
        const url = `${process.env.REACT_APP_API_ENDPOINT}/api/review/delete/${wine.review._id}`;

        const data = {
            userId
        };

        fetch(url, {
            method: 'DELETE',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(reviews => {
                dispatch(setUserReviews(reviews));
                fetchReviewsForCurrentWine();
                setIsEditingReview(false);
                setRating(0);
                setGoodAsIs(false);
                setGoodForCompany(false);
            })
    };

    const addComment = () => {
        const url = `${process.env.REACT_APP_API_ENDPOINT}/api/comment/add`;

        const data = {
            userId,
            wineId: wine.id,
            comment: userComment,
            commentIsPublic,
        };

        fetch(url, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(comments => {
                const currentUserComments = comments.filter(comment => comment.userId === userId);
                dispatch(setComments(currentUserComments, currentWineComments));
                setUserComment('');
                setCommentExpanded(false);
            })
    };

    const StarsRating = () => (
        <React.Fragment>
            <ReviewWithStars onChange={value => setRating(value)} rating={rating}/>

            <div className={`rating-labels-container ${isEditingReview || rating !== 0 ? 'expanded' : 'collapsed'}`}>
                <label>
                    <input type="checkbox" checked={goodAsIs} onChange={(event) => setGoodAsIs(event.target.checked)}/>
                    Sopii nautiskeluun
                </label>
                <label>
                    <input type="checkbox" checked={goodForCompany} onChange={(event) => setGoodForCompany(event.target.checked)}/>
                    Sopii seurusteluun
                </label>
            </div>
        </React.Fragment>
    );

    const leaveReview = () => {
        return (
            isRatedByCurrentUser ?
                <React.Fragment>
                    {isEditingReview ?
                        <React.Fragment>
                            <h3>Muokkaa arviota</h3>
                            <StarsRating/>

                            {currentUserComments.map((comment, key) => <Comment comment={comment} key={key} shouldShowDelete={isEditingReview}/>)}
                            <button className="small delete" onClick={() => deleteReview()}>Poista arvio</button>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <h3>Oma arvio</h3>
                            <div className="date">{dayjs(review.date).format('D.M.YYYY')}</div>
                            <StarsByRating rating={review.rating} isReviewedByCurrentUser={true}/>

                            {review.goodAsIs && <div className="sopii"><IoIosWine/> Nautiskeluun</div>}
                            {review.goodForCompany && <div className="sopii"><FaUserFriends/> Seurusteluun</div>}

                            <div className="current-user-comments">
                                {currentUserComments.map((comment, key) => <Comment comment={comment} key={key} shouldShowDelete={isEditingReview}/>)}
                            </div>

                            <div className="add-comment-button" onClick={() => setCommentExpanded(true)}><FaPlus /> Lisää kommentti</div>

                            {commentExpanded &&
                            <div className="leave-comment">
                                <label>
                                    <textarea
                                        onChange={(e) => setUserComment(e.target.value)}/>
                                </label>
                                <label>
                                    <input type="checkbox"
                                           checked={commentIsPublic}
                                           onChange={(event) => setCommentIsPublic(event.target.checked)}/>
                                    Kommentti on julkinen
                                </label>

                                <button disabled={userComment.length === 0} onClick={() => addComment()}>
                                    Lähetä
                                </button>
                            </div>
                            }
                        </React.Fragment>
                    }
                </React.Fragment>
                :
                <React.Fragment>
                    <h3>Jätä arvio</h3>
                    <StarsRating/>
                </React.Fragment>
        )
    };

    if (!userId) {
        return (
            <div className="please-login">
                <h4>Kirjaudu sisään</h4>
                <p>Kirjautuneet käyttäjät voivat jättää arvioita ja tallentaa viinejä muistilistalle.</p>
            </div>
        )
    }

    return (
        <div className="review-block leave-or-edit-review">
            {leaveReview()}

            <div className="buttons">
                {isEditingReview ?
                    <button disabled={!rating} onClick={() => updateReview()}>
                        Tallenna arvio
                    </button>
                    :
                    isRatedByCurrentUser ?
                        <button onClick={() => setIsEditingReview(true)}>
                            Muokkaa arviota
                        </button>
                        :
                        <button disabled={!rating} onClick={() => addReview(wine.id)}>
                            Tallenna arvio
                        </button>
                }
                <BookmarkButton/>
            </div>
        </div>
    )
};

export default LeaveOrEditReview