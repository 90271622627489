import React from 'react';
import {useWine} from "../../helpers/hooks";
import _ from 'lodash'
import WineListItem from "../wine-list-item/wine-list-item";
import {filterWinesByCategory} from "../../helpers/helpers";
import {useSelector} from "react-redux";

const SimilarWines = () => {
    const {wines} = useSelector(state => state.wines);
    const wine = useWine();

    if (!wine || !wines) {
        return
    }

    const sameCategoryWines = filterWinesByCategory(wines, wine.type);

    const findSimilarWines = () => {
        const currentWine = wine;

        const matchingWines = sameCategoryWines.map(wine => {
            const matchingCountry = wine.country === currentWine.country ? 10 : 5;
            const matchingRegion = wine.region === currentWine.region ? 10 : 5;
            const matchingDescription = wine.description[0] === currentWine.description[0] ? 10 : 7;
            const grapesDiff = currentWine.grapes.filter(x =>  wine.grapes.includes(x));

            const grapesScore = Math.floor((grapesDiff.length / currentWine.grapes.length) * 10);
            
            const difference = (a, b) => Math.abs(a - b);
            
            const calculateAlcoholDiff = (originalAlcohol, comparisonAlcohol) => {
                if (difference(originalAlcohol, comparisonAlcohol) <= 0.5) {
                    return 10
                } else if (difference(originalAlcohol, comparisonAlcohol) <= 1) {
                    return 8
                } else if (difference(originalAlcohol, comparisonAlcohol) <= 1.5) {
                    return 6
                } else if (difference(originalAlcohol, comparisonAlcohol) <= 2) {
                    return 4
                } else {
                    return 1
                }
            }

            const calculateSugarDiff = (originalSugar, comparisonSugar) => {
                if (difference(originalSugar, comparisonSugar) <= 0.5) {
                    return 10
                } else if (difference(originalSugar, comparisonSugar) <= 1) {
                    return 8
                } else if (difference(originalSugar, comparisonSugar) <= 1.5) {
                    return 6
                } else if (difference(originalSugar, comparisonSugar) <= 2) {
                    return 4
                } else {
                    return 1
                }
            }

            const calculateAcidsDiff = (originalAcids, comparisonAcids) => {
                if (difference(originalAcids, comparisonAcids) <= 0.5) {
                    return 10
                } else if (difference(originalAcids, comparisonAcids) <= 1) {
                    return 8
                } else if (difference(originalAcids, comparisonAcids) <= 1.5) {
                    return 6
                } else if (difference(originalAcids, comparisonAcids) <= 2) {
                    return 4
                } else {
                    return 1
                }
            }

            const alcoholScore = currentWine.alcohol ? calculateAlcoholDiff(parseInt(currentWine.alcohol), parseInt(wine.alcohol) ? parseInt(wine.alcohol): 0) : 0;
            const sugarScore = currentWine.sugar ? calculateSugarDiff(parseInt(currentWine.sugar), parseInt(wine.sugar) ? parseInt(wine.sugar) : 0) : 0;
            const acidsScore = currentWine.sugar ? calculateAcidsDiff(parseInt(currentWine.acids), parseInt(wine.acids) ? parseInt(wine.acids) : 0) : 0;
            
            const totalScore = (matchingCountry + matchingRegion + matchingDescription + grapesScore + alcoholScore + sugarScore + acidsScore);

            return {
                ...wine,
                matchingScore: totalScore,
                matchingDetails: {
                    country: matchingCountry,
                    region: matchingRegion,
                    description: matchingDescription,
                    grapes: grapesScore,
                    alcohol: alcoholScore,
                    sugar: sugarScore,
                    acids: acidsScore
                }
            }
        });

        return _.orderBy(matchingWines, "matchingScore", 'desc').filter(w => w.id !== currentWine.id).slice(0, 20)
    };

    const similarWines = findSimilarWines()

    return (
        <div>
            <h3>
                Samankaltaisia viinejä
            </h3>

            <div>
                {similarWines.map((wine, key) =>
                    <WineListItem wine={wine} key={key} isInGrid={false} isMatchingWine={true}/>
                )}
            </div>
        </div>
    )
};

export default SimilarWines