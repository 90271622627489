import React, {Component} from 'react';

class PrivacyPage extends Component {
    render() {
        return (
            <div>
                <h1>Tietosuoja</h1>
                <p>
                    Kaikki palveluun jätetty tieto on anonyymiä. Muut käyttäjät tai sivuston ylläpitäjä ei saa haltuunsa esimerkiksi käyttäjän nimeä, sähköpostia tai muuta tietoa, joita mahdollisesti on liitetty kolmannen osapuolen kirjautumispalveluun.
                </p>
                <p>
                    Käyttäjästä tallennetaan kolmannelta osapuolelta saatava tunniste (id), sekä palvelun käytön kannalta välttämättömät tiedot, esimerkiksi. arvio, kommentti, kirjanmerkki.
                </p>
                <p>
                    Sivuston ylläpitäjää voi pyytää poistamaan tiettyä tunnistetta koskevat tiedot. Sivuston ylläpito: <a href="mailto:aki.narhi@reaktor.com">aki.narhi@reaktor.com</a>
                </p>
            </div>
        )
    }
}

export default PrivacyPage