import FilterRow from "../filter-row/filter-row";
import React from "react";
import {addFilterDescription, removeFilterDescription} from "../../state/actions";
import {useDispatch} from "react-redux";
import {useDescriptions, useFilters, useTargetFilters} from "../../helpers/hooks";
import FilterGroup from "./filter-group";

const FilterByDescription = () => {
    const dispatch = useDispatch();
    const descriptions = useDescriptions();
    const targetFilters = useTargetFilters();
    const {selectedDescriptions} = useFilters();
    const currentDescriptionIsSelected = description => selectedDescriptions.includes(description);
    const handleDescriptionFilterClick = description => currentDescriptionIsSelected(description) ? dispatch(removeFilterDescription(description, targetFilters)) : dispatch(addFilterDescription(description, targetFilters));

    return (
        <FilterGroup title="Kuvaus">
            {descriptions.map((description, key) => (
                <FilterRow
                    key={key}
                    title={description.description}
                    amount={description.amount}
                    isSelected={currentDescriptionIsSelected(description.description)}
                    clickFn={() => handleDescriptionFilterClick(description.description)}
                />
            ))}
        </FilterGroup>
    )
};

export default FilterByDescription