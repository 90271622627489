import { combineReducers } from "redux"
import userReducer, {userInitialState} from "./userReducer";
import commonReducer, {commonInitialState} from "./commonReducer";
import winesReducer, {winesInitialState} from "./winesReducer";
import wineDetailsRecuder, {wineDetailsInitialState} from "./wineDetailsRecuder";
import filtersReducer, {filtersInitialState} from "./filtersReducer";

const rootReducer = combineReducers({
    common: commonReducer,
    user: userReducer,
    wines: winesReducer,
    wineDetails: wineDetailsRecuder,
    filters: filtersReducer
});

export const initialState = {
    common: commonInitialState,
    user: userInitialState,
    wines: winesInitialState,
    wineDetails: wineDetailsInitialState,
    filters: filtersInitialState
};

export default rootReducer
