import React from 'react';
import {useDispatch} from "react-redux";
import {addFilterRegion, removeFilterRegion} from "../../state/actions";
import {FaCheckSquare, FaRegSquare} from "react-icons/all";
import {useFilters, useTargetFilters} from "../../helpers/hooks";

const FilterRow = props => {
    const {
        clickFn,
        title,
        amount,
        isSelected,
        regions,
    } = props;

    const dispatch = useDispatch();
    const {selectedRegions} = useFilters();
    const targetFilters = useTargetFilters();

    const currentRegionIsSelected = region => selectedRegions.includes(region);
    const handleRegionFilterClick = region => currentRegionIsSelected(region) ? dispatch(removeFilterRegion(region, targetFilters)) : dispatch(addFilterRegion(region, targetFilters));

    return (
        <div className="filter-row">
            <label>
                <input type="checkbox" checked={isSelected} onChange={clickFn}/>
                {isSelected ?
                    <FaCheckSquare/>
                    :
                    <FaRegSquare/>
                }

                <span className="title">{title}</span> {amount && <span className="amount">({amount})</span>}
            </label>

            {regions && isSelected &&
            regions.map((region, key) => (
                <div className="inner-list filter-row" key={key}>
                    <label key={key}>
                        <input type="checkbox" checked={currentRegionIsSelected(region.region)}
                               onChange={() => handleRegionFilterClick(region.region)}/>
                        {currentRegionIsSelected(region.region) ?
                            <FaCheckSquare/>
                            :
                            <FaRegSquare/>
                        }

                        <span className="title">{region.region}</span> {region.amount &&
                    <span className="amount">({region.amount})</span>}
                    </label>
                </div>
            ))
            }
        </div>
    )
};

export default FilterRow