import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {setUserBookmarks} from "../../state/actions";
import {FaBookmark} from "react-icons/all";
import {useWine} from "../../helpers/hooks";

const BookmarkButton = () => {
    const dispatch = useDispatch();
    const wine = useWine();
    const {id} = wine;
    const {userId, bookmarks} = useSelector(state => state.user);
    const isBookmark = bookmarks.map(wine => wine.id).includes(id);

    const addOrDeleteBookmark = (method, id) => {
        const url = `${process.env.REACT_APP_API_ENDPOINT}/api/user/bookmarks/${id}`;
        const data = {
            userId
        };

        fetch(url, {
            method: method,
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(bookmarks => {
                dispatch(setUserBookmarks(bookmarks));
            })
    };

    const addBookmark = (id) => {
        addOrDeleteBookmark('PUT', id)
    };

    const deleteBookmark = (id) => {
        addOrDeleteBookmark('DELETE', id)
    };

    return isBookmark ?
        <button className="delete-bookmark icon-button" onClick={() => deleteBookmark(wine.id)}><FaBookmark /> Poista muistilistalta</button>
        :
        <button disabled={!userId} className="add-bookmark icon-button" onClick={() => addBookmark(wine.id)}><FaBookmark /> Lisää muistilistalle</button>
};

export default BookmarkButton;