import {bottleSizes} from "../../state/filtersReducer";
import RadioButtonRow from "./radio-button-row";
import React from "react";
import {useDispatch} from "react-redux";
import {setFilterBottleSize} from "../../state/actions";
import FilterGroup from "./filter-group";
import {useFilters, useTargetFilters} from "../../helpers/hooks";

const FilterByBottleSize = () => {
    const dispatch = useDispatch();
    const targetFilters = useTargetFilters();
    const {selectedBottleSize} = useFilters();

    const currentBottleSizeIsSelected = size => selectedBottleSize === size;
    const handleBottleSizeFilterClick = size => dispatch(setFilterBottleSize(size, targetFilters));

    return (
        <FilterGroup title="Pakkauskoko">
            <RadioButtonRow value="Kaikki" group="pakkauskoko" isSelected={currentBottleSizeIsSelected('all')} onChangeFn={() => handleBottleSizeFilterClick('all')} />

            {bottleSizes.map((size, key) => <RadioButtonRow key={key} value={size} group="pakkauskoko" isSelected={currentBottleSizeIsSelected(size)} onChangeFn={() => handleBottleSizeFilterClick(size)}/>)}
        </FilterGroup>
    )
};

export default FilterByBottleSize